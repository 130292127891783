/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Button} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

import {
    clearSelected,
    setSearchCriteria,
    getAccountsCount,
    getAccounts,
    clearSelectedAccounts
} from "./managedAccountsSlice";

import BreadCrumb from "../../common/components/BreadCrumb";
import DropdownAction from "../../common/components/DropdownAction";
import PanelHeader from "../../common/components/layout/PanelHeader";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";
import AccountsTable from "./AccountsTable";
import FormTextInput from "../../common/components/FormTextInput";

import PanelTable from "../../common/components/layout/PanelTable";
import Container from "../../common/components/layout/Container";

const Accounts = (props) => {
    return (
        <AccountsComponent
        />
    );
};

const AccountsComponent = (props) => {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Accounts',
        href: '',
        isActive: true
    }];

    const accountsState = useSelector(state => state.managedAccounts);
    const dispatch = useDispatch();
    const history = useHistory();

    const [lastReload, setLastReload] = useState(Date.now());
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        dispatch(clearSelectedAccounts());
    }, [dispatch]);

    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(clearSelected());
    }, [lastReload, dispatch]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (accountsState.alertMessage) {
            setAlertMessage(accountsState.alertMessage);
            setShowAlert(true);
        }
    }, [accountsState.alertMessage]);

    const isReload = accountsState.reload === true;

    useEffect(() => {
        dispatch(getAccountsCount());
        dispatch(getAccounts());
    }, [isReload, dispatch]);

    const handleOnKeyDown = (target) => {
        if (target.code === 'Enter') {
            setLastReload(Date.now())
        }
    }

    const handleRefresh = (target) => {
        setLastReload(Date.now());
    }

    const getActionItems = () => {
        const actionItems = [
            {
                label: 'EDIT',
                href: '',
                isActive: accountsState?.selectedId?.length === 1,
                onClickHandler: () => history.push(`/admin/Accounts/${accountsState.selectedId[0]}/managed-accounts`)
            }, {
                label: 'DISABLE',
                href: '',
                isActive: accountsState?.selectedId?.length === 1 && accountsState?.selectedAccounts[0]?.statusId === 'ACTIVE',
                onClickHandler: () => history.push(`/admin/Accounts/${accountsState.selectedId[0]}/disable-account`)
            }, {
                label: 'ENABLE',
                href: '',
                isActive: accountsState?.selectedId?.length === 1 && accountsState?.selectedAccounts[0]?.statusId === 'DISABLED',
                onClickHandler: () => history.push(`/admin/Accounts/${accountsState.selectedId[0]}/enable-account`)
            }
        ];

        return actionItems;
    };

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}></BreadCrumb>
            <PanelHeader
                header={`Accounts (${accountsState.totalRecords.toLocaleString()})`}
                toolAction={
                    <div>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="outline-secondary"
                            onClick={handleRefresh}
                        >REFRESH
                        </Button>
                        <DropdownAction items={getActionItems()}></DropdownAction>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="info"
                            onClick={() => history.push('/admin/accounts/create-account')}
                        >
                            CREATE
                        </Button>
                    </div>
                }
                searchBar={
                    <FormTextInput
                        placeholder={'Search by id or email...'}
                        className={'accountsSearchText'}
                        value={accountsState.searchCriteria}
                        onChange={(value) => {
                            dispatch(setSearchCriteria(value));
                        }}
                        onKeyDown={handleOnKeyDown}
                        clearable
                        clearableHandle={() => {
                            dispatch(setSearchCriteria(''));
                            setLastReload(Date.now());
                        }}
                    >
                    </FormTextInput>
                }
            />
            <PanelTable>
                {accountsState.loading && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={showAlert}
                    onClose={() => setShowAlert(!showAlert)}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
                <AccountsTable lastReload={lastReload}/>
            </PanelTable>
        </Container>
    );
};

export default Accounts;
